.mobile-navigation{
  height: 100%;
  .ant-tabs-tab{
      padding: 8px 24px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      .anticon{
          margin-top: 0;
          margin-right: 12px;
      }
      &+.ant-tabs-tab{
          margin: 16px 0 0 0;
      }
      &.ant-tabs-tab-active{
          border-left: 2px solid #de1186;
      }
  }
}