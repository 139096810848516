.faq-page{
  padding: 20px;
  width: 100%;
  @media only screen and (min-width: 992px) {
    width: calc(100% - 215px);
    padding: 30px 40px;
  }
  h2{
    color: #2E1C41;
    font-size: 20px;
    margin: 0;
  }
  .faq-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .faq {
    .ant-table-tbody{
      >tr{
        >td:first-child, >td:nth-child(2){
          color: #423254;
          cursor: initial;
        }
      }
    }
  }
}

.ant-pagination{
  .ant-pagination-item-active{
    border-color: #DE1186 !important;
    a{
      color: #DE1186;
    }
  }
  .ant-pagination-item{
    &:hover{
      a{
        color: #DE1186;
        opacity: 0.7;
      }
    }
  }
  .ant-pagination-jump-next, .ant-pagination-jump-prev{
    .ant-pagination-item-container{
      .ant-pagination-item-link-icon{
        color: #DE1186;
      }
    }
  }
}

.ant-pagination-options{
  .ant-select{
    &:hover{
      .ant-select-selector{
        border-color: #DE1186 !important;
      }
    }
    &.ant-select-open{
      .ant-select-selector{
        border-color: #DE1186 !important;
      }
    }
  }
}