.count-wrapper{
  background: #FFFFFF;
  box-shadow: 0 13px 25px #0000000A;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 208px;
  align-self: stretch;
  min-width: 0;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 47.2%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  .count{
    color: #3E2B82;
    font-family: Poppins;
    font-weight: bold;
    font-size: 30px;
    margin: 0 0 20px 0;
  }

  .title{
    color: #2E1C41;
    font-family: Poppins;
    font-size: 14px;
    margin: 0;
    word-break: break-word;
  }
}