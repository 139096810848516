body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pr-button {
  width: 162px;
  height: 42px;
  background: #de1186 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  color: #ffffff;
  font-size: 12px;
  font-weight: 800;
  &.ant-btn-default {
    &:hover {
      color: #fff;
      border-color: #fff;
      opacity: 0.8;
    }
  }
}

.sec-button {
  width: 162px;
  height: 42px;
  border-radius: 10px;
  border: 1px solid #c33381;
  opacity: 1;
  color: #c33381;
  font-size: 12px;
  background: transparent;
  font-weight: 800;
}

.download-button {
  min-width: 90px;
  height: 40px;
  color: #de1186;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 40px #0000000a;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.clear-filters {
  height: 40px;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 40px #0000000a;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
}
