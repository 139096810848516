.navigation {
  background-color: #ffffff !important;
  box-shadow: 0px 5px 40px #0000000d;
  height: 100%;
  padding-top: 25px;
  font-weight: 800;
  @media only screen and (max-width: 991px) {
    max-width: 0 !important;
    min-width: 0 !important;
    width: 0 !important;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: ease-out 0.4s;
    &.show {
      visibility: visible;
      opacity: 1;
      max-width: 100vw !important;
      min-width: 100vw !important;
      width: 100vw !important;
      height: 100%;
    }
  }
  .ant-tabs-nav {
    max-width: 100%;
    min-width: 100%;
    @media only screen and (max-width: 991px) {
      width: 100vw !important;
    }
  }
  .ant-tabs-ink-bar {
    left: 0 !important;
    background-color: #de1186 !important;
  }

  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: #423254;
      &:focus {
        color: #423254;
      }
      > span {
        display: flex;
        align-items: center;
        line-height: 1;
        white-space: normal;
        text-align: left;
        @media only screen and (max-width: 1403px) and (min-width: 481px) {
          align-items: flex-start;
          line-height: 1.5;
        }
      }
      .anticon {
        > svg {
          @media only screen and (min-width: 1155px) and (max-width: 1320px) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .anticon-user {
      @media only screen and (max-width: 1403px) and (min-width: 481px) {
        margin-top: 2px;
      }
    }
    .external-link {
      position: relative;
      > span {
        margin-left: 2px;
        margin-bottom: 5px;
        display: inline-block;
        width: 8px;
        height: 8px;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    &:hover {
      opacity: 0.7;
      transition: all 0.3s;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #de1186 !important;
    }
  }

  .ant-tabs-tab:hover {
    color: unset !important;
  }
  .ant-tabs-tabpane {
    background: #fafafa 0% 0% no-repeat padding-box;
  }
  .ant-layout-sider-children {
    height: 100%;
  }
  .ant-tabs-content-holder {
    border: none;
  }
  .nav-footer {
    padding: 8px 24px;
    text-align: center;
    color: #2e1c41;
    font-weight: 700;
    background-color: #f8f8f8;
    position: absolute;
    bottom: 0;
    width: 215px;
    min-width: 100%;
    max-width: 100%;
  }
  .ant-tabs-nav-list {
    padding-bottom: 32px;
  }
}
