.float-label {
  position: relative;

  span {
    &:hover {
      z-index: 0 !important;
    }

    &:focus {
      z-index: 0 !important;
    }
  }
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 15px;
  transition: 0.2s ease all;
}

.as-placeholder {
  color: gray;
}

.as-label {
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: 2px;
  z-index: 3;
}

.ant-select-item-option-content {
  white-space: break-spaces !important;
  word-break: break-word;
}
