.faq-page{
  padding: 20px;
  width: 100%;
  @media only screen and (min-width: 992px) {
    width: calc(100% - 215px);
    padding: 30px 40px;
  }
  &.manage-faq-page{
    @media only screen and (max-width: 991px) {
      height: 100vh;
      overflow: auto;
    }
  }
  &.faq-category-page{
    @media only screen and (max-width: 991px) {
      height: 100vh;
      overflow: auto;
    }
  }

  .faq {
    .ant-table-tbody{
      >tr{
        >td:first-child, >td:nth-child(2){
          color: #423254;
          cursor: initial;
          word-break: break-word;
        }
      }
    }
  }
  
  .add-faq-btn{
    color: #fff;
    background: #DE1186;
    border: none;
    font-weight: 800;
    min-width: 150px;
    min-height: 42px;
    box-shadow: 0px 7px 40px #ccc;
    display: block;
    margin-left: auto;
    margin-bottom: 20px;
    &:hover{
      opacity: 0.6;
      color: #fff;
    }
  }
  
  .table-paginated{
    .actions{
      display: flex;
      align-items: center;
      span{
        font-size: 20px;
      }
      .edit{
        margin-right: 10px;
      }
      .edit, .delete{
        cursor: pointer;
      }
    }
  }
}

.ant-popconfirm-buttons{
  .ant-btn-primary{
    background-color: #DE1186;
    &:hover{
      background-color: #e195c0;
    }
  }
  .ant-btn-default{
    color: #DE1186;
    border-color: #DE1186;
    &:hover{
      color: #DE1186;
      border-color: #DE1186;
      opacity: 0.5;
    }
  }
}