.header {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.875rem;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 5px 40px #0000000D;
    align-items: center;
    position: relative;
    z-index: 1;
    .hamburger-menu{
        @media only screen and (min-width: 992px) {
            display: none;
        }
        @media only screen and (max-width: 991px) {
            max-width: 16px;
        }
        .show-menu-icon{
            visibility: visible;
            opacity: 1;
            width: 100%;
            height: 100%;
            transition: all 0.4s;
        }
        .hide-menu-icon{
            visibility: hidden;
            opacity: 0;
            width: 0;
            height: 0;
            transition: all 0.4s;
        }
    }
    .left-section {
        img{
            @media only screen and (max-width: 991px) {
                max-width: 140px;
            }
        }
    }

    .right-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 25px;
            @media only screen and (max-width: 991px) {
                gap: 10px;
            }
    }

    .header-icon{
        border-radius: 50%;
        background-color: #efefef;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.applications, .profile, .bell-icon{
    cursor: pointer;
}

.bell-icon{
    position: relative;
    &.ant-dropdown-open{
        background: #DE1186 url('./HeaderIcons/notification_white.svg') no-repeat 5px 5px;
        img{
            opacity: 0;
        }
        .notification-count{
            box-shadow: none;
        }
    }
    @media only screen and (min-width: 992px) {
        &:hover{
            background: #DE1186 url('./HeaderIcons/notification_white.svg') no-repeat 5px 5px;
            img{
                opacity: 0;
            }
            .notification-count{
                box-shadow: none;
            }
        }
    }
    .notification-count{
        width: 18px;
        height: 18px;
        background: #DE1186;
        box-shadow: 0 4px 6px #ebb2d2;
        color: #fff;
        font-size: .65rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        position: absolute;
        align-items: center;
        right: -5px;
        top: -11px;
        font-weight: 800;
    }
}

.applications{
    &.ant-dropdown-open{
        background: #DE1186 url('./HeaderIcons/app_white.svg') no-repeat 5px 5px;
        img{
            opacity: 0;
        }
    }
    @media only screen and (min-width: 992px) {
        &:hover{
            background: #DE1186 url('./HeaderIcons/app_white.svg') no-repeat 5px 5px;
            img{
                opacity: 0;
            }
        } 
    }
}

.applications-dropdown{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px;
    margin: 0;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 10px 20px #00000012;
    max-width: 370px;
    @media only screen and (max-width: 991px) {
        max-width: 340px;
    }
    li{
        margin-bottom: 15px;
        width: 33%;
        list-style: none;
        a{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            position: relative;
            cursor: pointer;
            .app-icon{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid #F5F6F8;
                background-repeat: no-repeat;
                background-position: center;
                background-color: #f5f6f8;
                background-size: 30px 100%;
                &:hover{
                    background-color: #de1186;
                    border-color: #de1186;
                }
            }
            .learning-icon{
                background-image: url('./HeaderIcons/learning-icon.svg');
                &:hover{
                    background-image: url('./HeaderIcons/learning-white.svg');
                }
            }
            .growth-icon{
                background-image: url('./HeaderIcons/growth-icon.svg');
                &:hover{
                    background-image: url('./HeaderIcons/growth-white.svg');
                }
            }
            .referral-icon{
                background-image: url('./HeaderIcons/referral-icon.svg');
                &:hover{
                    background-image: url('./HeaderIcons/referral-white.svg');
                }
            }
            .investment-icon{
                background-image: url('./HeaderIcons/investment-icon.svg');
                &:hover{
                    background-image: url('./HeaderIcons/investment-white.svg');
                }
            }
            .asset-icon{
                background-image: url('./HeaderIcons/asset-icon.svg');
                &:hover{
                    background-image: url('./HeaderIcons/asset-white.svg');
                }
            }
            .claims-icon{
                background-image: url('./HeaderIcons/claims-icon.svg');
                &:hover{
                    background-image: url('./HeaderIcons/claims-white.svg');
                }
            }
            .timesheet-icon{
                background-image: url('./HeaderIcons/timesheet-icon.svg');
                &:hover{
                    background-image: url('./HeaderIcons/timesheet-white.svg');
                }
            }
        }
        p{
            color: #2e1c41;
            font-size: .625rem;
            font-weight: 400;
            text-align: center;
        }
    }
}

.user-profile{
    background: #fff;
    box-shadow: 0 0 20px #ccc;
    width: 100px;
    border-radius: 10px;
    padding: 10px;
    li{
        color: #000;
        text-align: center;
        display: block;
        padding: 5px;
        font-weight: 800;
        &:hover{
            background: #e8f0fe;
            border-radius: 8px;
            color: #DE1186;
            cursor: pointer;
        }
    }
}

.notifications-dropdown{
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 20px #dfdfdf;
    width: 315px;
    padding: 0;
    max-height: 400px;
    overflow: auto;
    li{
        list-style: none;
        font-size: 14px;
        color: #DE1186;
        font-weight: 800;
        padding: 20px;
        &:hover{
            cursor: pointer;
            opacity: 0.7;
            background-color: #e8f0fe;
            border-radius: 8px;
        }
        &:not(:last-child){
            border-bottom: 1px solid #e3e0e0;
        }
    }
}