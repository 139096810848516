.faq-page {
  padding: 20px;
  width: 100%;
  @media only screen and (min-width: 992px) {
    width: calc(100% - 215px);
    padding: 30px 40px;
  }

  .faq-title {
    .search-input-wrapper {
      max-width: 350px;
      width: 100%;
      position: relative;
    }

    .search-input {
      box-shadow: 0px 7px 40px #0000000a;
      border: none;
      height: 40px;
      width: 100%;
      padding-right: 105px;
    }

    .clear-search {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      .clear-text {
        color: #de1186;
        font-size: 11px;
        padding-left: 6px;
      }

      .anticon-close-circle {
        color: #a3a3a3;
      }
    }

    @media only screen and (max-width: 535px) {
      display: flex;
      flex-wrap: wrap;

      h2,
      .search-input-wrapper {
        margin: 5px 0px;
      }
    }
  }

  .table-paginated {
    .ant-table-tbody {
      > tr {
        > td:first-child {
          color: #de1186;
          cursor: pointer;
          font-weight: 800;
        }
        > td {
          border-bottom: 1px solid #f7f7f7;
          color: #2e1c41;
          &.ant-table-column-sort {
            background: #fff;
          }
        }
      }
    }
    .ant-table-content {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .ant-table-expanded-row-fixed {
      width: auto !important;
    }
    // .paginated-table-wrapper tr th,
    // .paginated-table-wrapper tr:not(:first-child) td {
    //   padding: 16px 24px !important;
    // }
  }
}
