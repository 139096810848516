.super-admin-home-wrapper {
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 991px) {
    flex-basis: initial;
    width: 100%;
  }

  .go-back {
    width: fit-content;
    color: #de1186;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .super-admin-home-display-counter {
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
    justify-content: flex-start;
    gap: 35px;
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      flex-wrap: wrap;
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      .count-wrapper {
        width: 100%;
      }
    }
  }
  .table-paginated {
    .ant-table-tbody {
      > tr {
        > td:first-child {
          color: #de1186;
          cursor: pointer;
          font-weight: 800;
        }
        > td {
          border-bottom: 1px solid #f7f7f7;
          color: #2e1c41;
          &.ant-table-column-sort {
            background: #fff;
          }
        }
      }
    }
    .ant-table-content {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .ant-table-expanded-row-fixed {
      width: auto !important;
    }
  }
}
