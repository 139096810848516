.create-faq-category-form{
  .ant-modal-title{
    text-align: center;
  }
  .ant-form{
    margin-top: 30px;
    .float-label {
      .ant-select-selector, .ant-input-affix-wrapper{
        height: 45px;
      }
      .ant-select-arrow{
        color: #000;
      }
      .label{
        &.as-placeholder{
          top: 11px;
        }
        &.as-label{
          z-index: 3;
        }
      }
      .ant-input-affix-wrapper{
        &:hover{
          border-color: #eb96c6;
          box-shadow: 0 0 10px #fdcfea;
        }
      }
      .ant-input-affix-wrapper-focused{
        border-color: #eb96c6;
        box-shadow: 0 0 10px #fdcfea;
      }
      .ant-select{
        &:hover{
          .ant-select-selector{
            border-color: #eb96c6;
          }
        }
      }
      .ant-select-focused{
        .ant-select-selector{
          border-color: #eb96c6 !important;
          box-shadow: 0 0 10px #fdcfea !important;
        }
      }
      textarea{
        min-height: 70px;
      }
    }
    .ant-form-item-explain-error{
      font-size: 12px;
    }
    .answer-text{
      .float-label{
        .ant-select-selector, .ant-input-affix-wrapper{
          height: 100%;
        }
      }
    }
  }
  .create-faq-btn{
    color: #fff;
    background: #DE1186;
    border: none;
    font-weight: 800;
    box-shadow: 0px 7px 40px #ccc;
    &:hover{
      opacity: 0.6;
      color: #fff;
    }
  }
}