* {
  box-sizing: border-box;
  font-family: var(--fontPopins);
}

html {
  height: 100%;
}

body, #root, .App, .App > div{
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 991px) {
  .navigation-wrapper{
    flex-flow: row wrap !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px){
  .nav-wrap{
    flex-basis: fit-content;
  }
}

@media only screen and (min-width: 992px) {
  .navigation-wrapper .nav-wrap{
    position: sticky;
    top: 0;
    min-height: 90vh;
  }
}

.navigation-wrapper{
  height: 100%;
}

.navigation-wrapper.nav-height{
  height: 100vh;
}


/* font family */
@font-face {
  font-family: Poppins;
  src: url("./Fonts/Poppins-Light.ttf"); 
}

:root {
  --fontPopins: 'Poppins', sans-serif;
}