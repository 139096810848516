.manage-referral-bonus{
  padding: 20px;
  width: 100%;
  @media only screen and (min-width: 992px) {
    width: calc(100% - 215px);
    padding: 30px 40px;
  }

  @media only screen and (max-width: 991px) {
    height: 100vh;
  }

  .ant-form{
    max-width: 500px;
    margin: auto;

    .float-label {
      .ant-select-selector, .ant-input-affix-wrapper{
        height: 45px;
      }
      .ant-select-arrow{
        color: #000;
      }
      .label{
        &.as-placeholder{
          top: 11px;
        }
        &.as-label{
          z-index: 3;
        }
      }
      >.ant-input{
        height: 45px;
      }
      .ant-input-affix-wrapper, >.ant-input{
        &:hover{
          border-color: #eb96c6;
          box-shadow: 0 0 10px #fdcfea;
        }
      }
      .ant-input-affix-wrapper-focused, >.ant-input:focus{
        border-color: #eb96c6;
        box-shadow: 0 0 10px #fdcfea;
      }
      .ant-select{
        &:hover{
          .ant-select-selector{
            border-color: #eb96c6;
          }
        }
      }
      .ant-select-focused{
        .ant-select-selector{
          border-color: #eb96c6 !important;
          box-shadow: 0 0 10px #fdcfea !important;
        }
      }
    }
    .ant-form-item-explain-error{
      font-size: 12px;
    }
    .ant-select-selection-item{
      display: flex;
      align-items: center;
    }
  }

  .experience-amount-wrapper{
    border: 1px dashed #DE1186;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 24px;
    position: relative;
    .required-field{
      color: #ff4d4f;
    }
    .amount-field{
      margin-bottom: 0;
    }
    .remove-btn{
      position: absolute;
      display: block;
      top: 0;
      right: -25px;
      font-size: 15px;
      color: #DE1186;
      @media only screen and (max-width: 991px){
        right: -18px;
      }
      &:hover{
        opacity: 0.6;
        transition: all 0.3s;
      }
    }
    .experience-field{
      margin-bottom: 0;
    }
    .amount-field{
      margin-top: 24px;
    }
  }

  .save-btn{
    color: #fff !important;
    background: #DE1186;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 150px;
    min-height: 42px;
    margin: auto;
    font-weight: 800;
    &:hover{
      opacity: 0.6;
    }
    span{
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .add-more-btn{
    color: #DE1186 !important;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #DE1186 !important;
    width: 150px;
    min-height: 42px;
    margin: auto;
    font-weight: 800;
    &:hover{
      opacity: 0.6;
    }
    span{
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .footer-btns{
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-form-item-control-input-content{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }
}