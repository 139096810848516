.table-wrapper {
  display: flex;
  flex-direction: column;
  &.newer-referrals {
    .ant-table-tbody {
      > tr {
        > td:nth-child(2) {
          cursor: initial;
          color: #2e1c41;
        }
      }
    }
  }
  &:not(.newer-referrals) {
    table {
      font-size: smaller;
    }
  }
}

.page-size-changer {
  display: flex;
  align-items: center;
  .select-page-size {
    margin-left: 10px;
    .ant-select-selector {
      border: none;
      border-color: none !important;
      box-shadow: 0px 7px 40px #0000000a !important;
      .ant-select-selection-item {
        font-weight: 800;
        color: #2e1c41;
      }
    }
    .ant-select-arrow {
      .anticon {
        svg {
          fill: #000;
        }
      }
    }
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  margin-top: 15px;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
}

.table-paginated {
  box-shadow: 0px 6px 30px #0000000d;
  border-radius: 15px;
  .ant-table-thead > tr > th {
    background: #fff;
    // white-space: nowrap;
    &.ant-table-column-has-sorters {
      &.ant-table-cell-fix-left {
        &:hover {
          background: #fff;
        }
      }
    }
    &.ant-table-column-sort {
      background: #fff;
    }
    .title {
      display: flex;
      align-items: center;
    }
    &::before {
      display: none;
    }
  }
  .ant-table-tbody {
    > tr {
      > td:first-child,
      > td:nth-child(2) {
        color: #de1186;
        cursor: pointer;
        font-weight: 800;
      }
      > td {
        border-bottom: 1px solid #f7f7f7;
        color: #2e1c41;
        &.ant-table-column-sort {
          background: #fff;
        }
      }
    }
  }
  .ant-table-content {
    padding-left: 25px;
    padding-right: 25px;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      border-radius: 5px;
      background-color: rgb(223, 221, 221);
    }
    &::-webkit-scrollbar-thumb:horizontal {
      background-color: rgb(161, 157, 157);
      border-radius: 5px;
    }
  }
  .ant-table-cell {
    @media only screen and (max-width: 991px) {
      background: #fff;
    }
  }
  .ant-spin-dot {
    @media only screen and (max-width: 991px) {
      position: sticky !important;
      top: 24% !important;
    }
  }
  .ant-table-column-sorters {
    display: inline-flex;
    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
      &.active {
        color: #de1186;
      }
    }
  }
}

.table-pagination {
  .ant-pagination-item-active {
    border-color: #e5e5e5;
    &:hover {
      border-color: #de1186;
      a {
        color: #de1186;
      }
    }
    a {
      color: #de1186;
    }
  }
}

@media only screen and (min-width: 992px) {
  .ant-pagination {
    text-align: right;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .ant-pagination {
    margin-top: 20px;
    .ant-pagination-total-text {
      display: block;
      margin-bottom: 10px;
      padding-left: 7px;
    }
    .ant-pagination-options {
      display: inline-block;
      padding-left: 5px;
      .ant-select {
        .ant-select-selector {
          padding: 0 7px;
          height: 35px;
          align-items: center;
        }
      }
    }
  }
}
