.loader-spinner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 991px) {
    position: fixed;
    z-index: 1;
  }
  .ant-spin-dot-item {
    background-color: #DE1186;
  }
  .ant-spin-text { 
    color: #DE1186; 
  }
}